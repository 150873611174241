<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">基础信息（技能鉴定）</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">培训类型维护</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="分类名称" class="searchboxItem ci-full">
              <span class="itemLabel">分类名称:</span>
              <el-input
                  v-model="categoryName"
                  type="text"
                  size="small"
                  clearable
                  placeholder="请输入分类名称"
              />
            </div>
            <el-button
                style="margin-left: 20px"
                class="bgc-bv"
                round
                @click="getData()"
            >查询</el-button
            >
            <div class="btnBox" style="margin-left: 20px">
              <el-button class="bgc-bv" round @click="handleCreate('add')"
              >新增</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="100px"
                  :index="indexMethod"
              />

              <el-table-column
                  label="分类名称"
                  align="center"
                  prop="categoryName"
                  show-overflow-tooltip
                  min-width="100"
              />

<!--              <el-table-column-->
<!--                  label="关联机构"-->
<!--                  align="center"-->
<!--                  prop="compName"-->
<!--                  show-overflow-tooltip-->
<!--              />-->
<!--              <el-table-column-->
<!--                  label="专题课程"-->
<!--                  align="center"-->
<!--                  prop="courseNames"-->
<!--                  show-overflow-tooltip-->
<!--                  min-width="100"-->
<!--              />-->
              <el-table-column
                  label="创建日期"
                  align="center"
                  prop="createTime"
                  show-overflow-tooltip
                  min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>

              <el-table-column
                  label="操作"
                  align="center"
                  width="260"
                  fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                      type="text"
                      style="padding: 0px 5px"
                      size="mini"
                      @click="handleCreate('edit', scope.row)"
                  >编辑
                  </el-button>
                  <el-button
                      type="text"
                      size="mini"
                      style="padding: 7px 10px"
                      @click="handleDel(scope.row.categoryId)"
                  >删除</el-button
                  >
<!--                  <el-popover-->
<!--                      placement="left-start"-->
<!--                      width="200"-->
<!--                      trigger="hover"-->
<!--                      :content="-->
<!--                      '/pages/packageA/declarationProcess/projectParticulars/index?id=' +-->
<!--                      scope.row.specialSubjectId-->
<!--                    "-->
<!--                  >-->
<!--                    <el-button type="text" slot="reference">查看链接</el-button>-->
<!--                  </el-popover>-->
<!--                  <el-button-->
<!--                      type="text"-->
<!--                      size="mini"-->
<!--                      style="padding: 7px 10px"-->
<!--                      @click="seeShare(scope.row)"-->
<!--                  >查看分享码</el-button-->
<!--                  >-->
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
        />
      </div>
    </div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="60%">
      <span style="width: 100%;">
        <div class="ovy-a">
            <div class="form-box">
              <el-form
                  ref="ruleForm"
                  label-width="7.5rem"
                  class="form"
                  :rules="rules"
                  :model="ruleForm"
              >
                <el-form-item label="分类名称：" prop="categoryName">
                  <el-input
                      v-model="ruleForm.categoryName"
                      size="small"
                      maxlength="20"
                      show-word-limit
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doAddSave()">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 查看分享吗的弹框 -->
<!--    <el-dialog-->
<!--        :title="seeShareData.title + '-分享码'"-->
<!--        :visible.sync="seeShareDialog"-->
<!--        width="30%"-->
<!--    >-->
<!--      <img-->
<!--          id="parter"-->
<!--          style="width: 60%; display: block; margin: auto"-->
<!--          :src="seeShareData.url"-->
<!--          alt=""-->
<!--      />-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--        <el-button-->
<!--            type="primary"-->
<!--            class="bgc-bv"-->
<!--            size="small"-->
<!--            round-->
<!--            @click="downloadShare"-->
<!--        >下载小程序码</el-button-->
<!--        >-->
<!--      </span>-->
<!--    </el-dialog>-->
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import html2canvas from "html2canvas";
export default {
  name: "trainingTypeMaintenance",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      stu:'',
      categoryName: "", //专题名称
      // compId: "", // 机构id
      // CompanyList: [], // 关联单位的数据
      //  二维码弹框数据
      // seeShareData: {
      //   url: "", // 二维码路径
      //   title: "", // 弹框标题
      // },
      // 二维码弹框 - 状态
      // seeShareDialog: false,

      dialogVisible: false,
      dialogTitle: '',
      ruleForm: {
        categoryId:'',
        categoryName:''
      },
      rules: {
        categoryName: [
          { required: true, message: "请输入分类名称", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  created() {
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        categoryName: this.categoryName,
        // compId: this.compId || null,
      };
      this.doFetch(
          {
            url: "/biz/ct/category/pageList",
            params,
            pageNum,
          }
      );
    },
    // 新增
    handleCreate(stu, row) {
      this.stu = stu
      // stu add 添加  edit  编辑
      this.ruleForm = {

      }
      if(stu == 'add'){
        this.dialogTitle = '新建分类'
        this.$set(this.ruleForm, 'categoryId', '')
        this.$set(this.ruleForm, 'categoryName', '')
      }
      if(stu == 'edit'){
        this.dialogTitle = '修改分类'
        this.$set(this.ruleForm, 'categoryId', row.categoryId)
        this.$set(this.ruleForm, 'categoryName', row.categoryName)
      }

      this.dialogVisible = true
      // this.$router.push({
      //   path: "/web/miniApp/thematicEdit",
      //   query: { specialSubjectId, stu },
      // });
    },
    // 添加编辑
    doAddSave(){
      const ruleForm = this.$refs.ruleForm;
      ruleForm.validate((valid) => {
        if (valid) {
          if(this.stu == 'add'){
            this.$post("/biz/ct/category/insert", {...this.ruleForm}).then((res) => {

              if (res.status == "0") {
                this.$message({
                  type: "success",
                  message: "添加成功",
                });
                this.dialogVisible = false
                this.getData(-1)
              }
            });
          }
          if(this.stu == 'edit'){
            this.$post("/biz/ct/category/modify", {...this.ruleForm}).then((res) => {

              if (res.status == "0") {
                this.$message({
                  type: "success",
                  message: "修改成功",
                });
                this.dialogVisible = false
                this.getData(-1)
              }
            });
          }

        }
      });

    },
    // 删除
    handleDel(categoryId) {
      this.$confirm("确定删除该分类吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
          .then(() => {
            this.$post(
                "/biz/ct/category/delete",
                { categoryId },
            ).then((res) => {
              if (res.status == "0") {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.dialogVisible = false
                this.getData(-1)
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    // 查看分享吗
    // seeShare(row) {
    //   this.seeShareData.title = row.specialSubjectName;
    //   this.seeShareData.url = row.qrcodeKey;
    //   this.seeShareDialog = true;
    // },
    // 下载小程序码
    // downloadShare() {
    //   window.open(this.seeShareData.url);
    // },
    // 关联单位
    // getCompanyList(query) {
    //   if (query.trim().length >= 2) {
    //     this.$post("/sys/company/queryCompanyList", { compName: query })
    //         .then((res) => {
    //           if (res.status == 0) {
    //             this.CompanyList = res.data || [];
    //           }
    //         })
    //         .catch(() => {
    //           return;
    //         });
    //   } else {
    //     this.CompanyList = [];
    //   }
    // },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
          window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh == true) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.pageNum = 1), this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}

.operationControl > div > div > span {
  min-width: 6rem;
}

.itemLabel {
  display: inline-block;
  width: 3rem;
}
</style>